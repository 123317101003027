<template>
  <div :class="[{'relative left-1/2 right-1/2 max-w-96vw -ml-48vw -mr-48vw': data.full_width }, 'pt-2 pb-2']">
    <swiper ref="swiper" :options="swiperOption">
      <swiper-slide v-for="(slide, index) in slides" :key="index" class="w-full lg:w-4/5">
        <template v-if="slide.mime === 'image/png' || slide.mime === 'image/jpg' || slide.mime === 'image/jpeg'">
          <figure>
            <img class="w-full" :alt="slide.alternativeText" v-if="data.full_width" :src="api + slide.url"/>
            <img class="w-full" :alt="slide.alternativeText" v-else-if="slide.formats.large" :src="api + slide.formats.large.url"/>
            <img class="w-full" :alt="slide.alternativeText" v-else-if="slide.formats.medium" :src="api + slide.formats.medium.url"/>
            <img class="w-full" :alt="slide.alternativeText" v-else-if="slide.formats.small" :src="api + slide.formats.small.url"/>
          </figure>
        </template>
        <template v-else-if="slide.mime === 'video/mp4'">
          <div @click="play(index)" class="flex items-center justify-center cursor-pointer">
            <div class="absolute w-20 z-10">
              <img v-if="playIndex !== index" class="w-full" src="@/assets/svg/play-white.svg"/>
            </div>
            <figure>
              <video :ref="`video-${index}`" :title="slide.alt" class="outline-none" nocontrols loop>
                <source :src="api + slide.url" :type="slide.mime">
                <meta itemprop="name" :content="slide.alternativeText">
                <meta itemprop="description" :content="slide.caption"> 
                {{ slide.alternativeText }}
              </video>
            </figure>
          </div>
        </template>
      </swiper-slide>
      <div 
        class="absolute p-3 top-2/4 z-10 cursor-pointer right-1" 
        slot="button-prev"
        @click="next"
      >
        <img class="w-3" src="@/assets/svg/arrow-black-right.svg"/>
      </div>
      <div 
        class="absolute p-3 top-2/4 z-10 cursor-pointer left-1" 
        slot="button-next" 
        @click="previous"
      >
        <img class="w-3 transform rotate-180" src="@/assets/svg/arrow-black-right.svg"/>
      </div>
    </swiper>
    <figcaption :class="{'container': data.full_width }" v-if="slides">{{ slides[0].caption }}</figcaption>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: 'CarouselBlock',
  props: ['data'],
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      slides: null,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 24,
        grabCursor: true,
        resistanceRatio: 0.1,
      },
      playIndex: null,
    }
  },
  mounted() {
    if (this.data.slides) {
      this.slides = this.data.slides
    } else if (this.data.images) {
      this.slides = this.data.images
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper
    }
  },
  methods: {
    next() {
      this.swiper.slideNext()
    },
    previous() {
      this.swiper.slidePrev()
    },
    play(el) {
      if (el !== this.playIndex) {
        this.playIndex = el
        this.$refs[`video-${el}`][0].play()
      } else {
        this.playIndex = null
        this.$refs[`video-${el}`][0].pause()
      }
    }
  }
}
</script>